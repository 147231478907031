<template>
  <chart-shell :title="title">
    <div>
      <div class="line-bar-chart" :id="title"></div>
    </div>
  </chart-shell>
</template>

<script>
  import ChartShell from "@c/report-detail/sleep-chart/chart-shell.vue";
  import { DateTool } from "@js/date-tool.js";
  export default {
    components: {
      ChartShell,
    },

    props: {
      title: String,
      model: Object,
      timeTickArray: Array,
    },

    data() {
      return {
      };
    },

    mounted() {
      this.chart = this.$echarts.init(document.getElementById(this.title));
      this.renderChart();
    },

    methods: {
      renderChart() {
        this.chart.setOption({
          xAxis: {
            type: "time",
            axisLine: {
              show: true,
              lineStyle: {
                color: "#000000",
                width: 1,
              },
            },
            axisLabel: {
              color: "#999999",
              fontSize: "11",
              show: true,
              showMaxLabel: true,
              showMinLabel: true,
              fontSize: '11',//字体大小
              formatter: function (value, index) {
                var date = new Date(value);
                var hour = date.getHours()
                var minutes = date.getMinutes()
                if (date.getHours() < 10) {
                  hour = '0' + date.getHours()
                }
                if (date.getMinutes() < 10) {
                  minutes = '0' + date.getMinutes()
                }
                var texts = [hour, minutes];

                // if (index === 0 || index == 6) {
                //   texts = [hour, minutes];
                // } else {
                //   if (minutes = '00') {
                //     texts = [hour, minutes];
                //   }
                // }
                return texts.join(':');
              }
            },
            splitLine: {
              show: false,
              color: "#C1C1C1"
            },
            axisTick: {		//x轴刻度线
              show: true,
              lineStyle: {
                width: 1,
              }
            },

          },
          yAxis: {
            name: "",
            type: "value",
            min: 0,
            max: 4,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#000000",
                width: 1,
              },
            },
            axisTick: {
              //Y轴刻度线
              show: {
                formatter: function (value, index) {
                  var result = false;
                  switch (value) {
                    case 0: true; break;

                    default: false;
                  }
                  return result;

                }
              },
              lineStyle: {
                width: 1,
              }
            },
            splitLine: {
              show: false,
            },

            axisLabel: {
              color: "#999999",
              fontSize: "11",
              formatter: function (value, index) {
                var result = "";
                switch (value) {
                  case 1: result = "深睡"; break;
                  case 2: result = "浅睡"; break;
                  case 3: result = "眼动"; break;
                  case 4: result = "清醒"; break;
                  default: "";
                }
                return result;

              }
            },
          },

          grid: {
            right: '2.15%',
            left: '5%',
            top: "5%",
            bottom: '10%'
          },
          series: [
            {
              name: "离床数据",
              type: "line",
              symbol: "none",

              data: this.model.breathLeavePeriodChartDataArray.map(item => [item.xValue, item.yValue]),
              lineStyle: {
                width: 1,
              },
              color: "#DCDCDC",
              connectNulls: false,
              showSymbol: false,
              hoverAnimation: false,//设置鼠标放上去动作
              areaStyle: {
                color: '#DCDCDC',
                opacity: 1.0
              },
              emphasis: {
                focus: 'none',
                areaStyle: {
                  color: '#DCDCDC',
                  opacity: 1.0 // 可根据需要调整透明度
                }
              },
            },
            {
              name: "睡眠分期趋势",
              type: "line",
              symbol: "none",
              data: this.model.breathPeriodChartDataArray?.map(item => [item.xValue, item.yValue]),
              lineStyle: {
                width: 1,
              },
              color: "#DC143C",
              connectNulls: false,
              showSymbol: false,
              symbol: 'circle',     //设定为实心点
              symbolSize: 8,   //设定实心点的大小
              hoverAnimation: true,//设置鼠标放上去动作
              itemStyle: {
                color: '#DC143C', // 红色填充
                borderColor: '#ffffff', // 白色边框
                borderWidth: 3 // 边框宽度
              },

            },


          ],
          tooltip: {
            show: true,
            trigger: "axis",
            formatter: (params) => {
              return this.formatterToolTip(params);
            },
            textStyle: {
              fontSize: "12"
            },
            axisPointer: {
              type: 'line',
              lineStyle: {
                type: 'solid',
                color: 'rgba(46, 139, 87, 0.6)',
              },

            }
          },

        }
        )


      },

      formatterToolTip(params) {
        var tip = "";
        let value = "";
        let unit = "";
        // let seriesName = item.seriesName;
        params.forEach((item, index) => {
          let timeStr1 = ""
          if (index == 0) {
            const timeStr = DateTool.millisecondTimestampToStr(
              item.data[0],
              "h:m"
            );
            tip += timeStr + "<br />";
            timeStr1 = timeStr
          }

          const marker = item.marker;
          let seriesName = item.seriesName;

          if (seriesName == "睡眠分期趋势") {
            switch (item.value[1]) {
              case 4:
                value = "清醒";
                break;
              case 3:
                value = "眼动";
                break;
              case 2:
                value = "浅睡";
                break;
              case 1:
                value = "深睡";
                break;
              case null:
                value = "离床";
                break;
              default:
                break;
            }
          }
          tip += marker + timeStr1 + "&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp" + value + "<br />";
        });

        return tip;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .line-bar-chart {
    width: 100%;
    height: rem(190);
    box-sizing: border-box;
    padding: 0 rem(25);
    margin-top: rem(10);
    margin-bottom: rem(10);
  }
</style>