<template>
    <p v-html="dealWithValue"></p>
</template>

<script>
export default {
    props: {
        value: String,
        title:String
    },

    computed: {
        dealWithValue: function () {
            if(this.title == '最长暂停和低通气发生于'){
                var value = "&nbsp"
                value = this.value?.replace('该事件发生于：','')
                return value
            }else if(this.title == '开始记录时间' || this.title == '结束记录时间'
            || this.title == '开始入睡时间' || this.title == '结束睡眠时间'
            || this.title == '总睡眠时长(TST)'){
                return this.value
            }else {
                const value = parseFloat(this.value);
                if (isNaN(value) || value < 0) {
                    return "&nbsp";
                } else {
                    return value;
                }
            }
           
           
         
        }
    },
};
</script>

<style lang="scss" scoped>
p {
    margin-bottom: rem(5);
    /* 底部到相邻元素顶部距离为10像素 */
}
</style>