<template>
    <!-- <chart-shell :title="title" :info="chartConfig.info"> -->
    <div>
        <p class="area-chart-title">{{ chartConfig.info }}</p>
        <div class="area-chart" :id="title"></div>
    </div>
    <!-- </chart-shell> -->
</template>

<script>
import ChartShell from "@c/report-detail/sleep-chart/chart-shell.vue";
import { DateTool } from "@js/date-tool.js";
import { Area } from "@antv/g2plot";

export default {
    components: {
        ChartShell,
    },

    props: {
        title: String,
        dataArray: Array,
        timeTickArray: Array,
        sn: String
    },

    data() {
        return {
            chartConfig: {
                info: "",
                yMin: 0,
                yMax: 0,
                yTickCount: 0,
                tooltipTitle: "",
                tooltipUnit: "",
            },
        };
    },

    mounted() {
        this.configureChart();
    },

    methods: {
        configureChart() {
            switch (this.title) {
                case "睡眠体动幅度":
                    this.chartConfig = {
                        info: "体动幅度",
                        yMin: 0,
                        yMax: this.sn.includes("M11B") ? 2 : 0.001,
                        yTickCount: 1,
                        tooltipTitle: "",
                        tooltipUnit: "",
                    };
                    break;
                default:
                    break;
            }
            this.renderChart();
        },

        renderChart() {
            let areaChart = new Area(this.title, {
                data: this.dataArray,
                xField: "xValue",
                yField: "yValue",
                meta: {
                    xValue: {
                        type: "time",
                        mask: "HH:mm",
                        tickMethod: () => {
                            return this.timeTickArray;
                        },
                    },
                },
                xAxis: {
                    line: {
                        style: {
                            stroke: "#333",
                        },
                    },
                    tickLine: {
                        style: {
                            stroke: "#333",
                        },
                    },
                },
                yAxis: {
                    min: this.chartConfig.yMin,
                    max: this.chartConfig.yMax,
                    tickCount: this.chartConfig.yTickCount,
                    grid: null,
                    line: {
                        style: {
                            stroke: "#333",
                        },
                    },
                    tickLine: {
                        style: {
                            stroke: "#333",
                        },
                    },
                },
                areaStyle: () => {
                    return {
                        fill: "#de2146",
                        fillOpacity: 1,
                    };
                },
                line: {
                    size: 0.1,
                },
                tooltip: {
                    showTitle: false,
                    formatter: (item) => {
                        switch (this.title) {
                            case "睡眠体动幅度":
                                return {
                                    name: DateTool.millisecondTimestampToStr(item.xValue, "h:m"),
                                    value: item.yValue,
                                };
                            default:
                                break;
                        }
                    },
                },
            });
            areaChart.render();
        },
    },
};
</script>

<style lang="scss" scoped>
.area-chart {
    width: 100%;
    height: rem(90);
    box-sizing: border-box;
    padding: 0 rem(0);
    margin-top: rem(10);
    margin-bottom: rem(10);

    &-title {
        font-family: "songti";
        font-size: 12px;
    }
}
</style>