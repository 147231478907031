<template>
  <div class="pulse-statistics">
    <data-abstract-unit
      title="平均脉率"
      :value="model.averagePulse"
    ></data-abstract-unit>
    <data-abstract-unit
      title="最大脉率"
      :value="model.maxPulse"
    ></data-abstract-unit>
    <data-abstract-unit
      title="最小脉率"
      :value="model.minPulse"
    ></data-abstract-unit>
  </div>
</template>

<script>
import DataAbstractUnit from "@c/report-detail/data-abstract/data-abstract-unit.vue";
export default {
  components: {
    DataAbstractUnit,
  },

  props: {
    model: Object,
  },
};
</script>

<style lang="scss" scoped>

.pulse-statistics {
  width: 100%;
  box-sizing: border-box;
  padding: rem(20) rem(20);
  display: flex;
  justify-content: space-between;
}
</style>