<template>
  <div class="report-bottom">
    <!-- 四川省中医院 -->
    <div class="report-bottom-qr-code" v-if="model.reportTemplateType == 5">
      <img class="report-bottom-qr-code-img" :src="qrUrl" />
    </div>
    <div class="report-bottom-content" :class="{ 'report-bottom-content-thin': isThinInfo }">
      <p class="report-bottom-content-info">注：本报告仅供临床医生参考！</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object
  },

  data() {
    return {
      qrUrl: 'https://bajie-sleep.oss-cn-hangzhou.aliyuncs.com/file/1706604514090.png',

    }
  },

  computed: {
    isThinInfo() {
      switch (this.model.reportTemplateType) {
        case REPORT_TEMPLATE.台州恩泽医疗中心集团恩泽医院:
        case REPORT_TEMPLATE.四川省中医院:
        case REPORT_TEMPLATE.南方医科大学顺德医院耳鼻咽喉头颈外科:
          return true;
        default:
          return false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.report-bottom {
  &-qr-code {
    display: flex;
    width: 100%;

    justify-content: center;

    &-img {
      width: rem(80);
      height: rem(80);
    }
  }

  &-content {
    font-size: rem(12);
    width: 100%;
    height: rem(80);
    margin-top: rem(60);

    &-thin {
      height: rem(30);
      margin-top: rem(15);
    }

    &-info {
      border-bottom: 1px solid #eeeeee;
      width: 100%;
      height: 80%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>