<template>
    <div class="warning">
        <div class="warning-content">
            缺少血氧数据，仅供参考，请结合临床！
        </div>
        <img
          class="warning-close"
          src="@imgs/warning_close.png"
          @click="onCloseClick"
        />
    </div>
</template>

<script>
export default {
    methods:{
        onCloseClick(){
            this.$emit('close', false);
        }
    }
};

</script>
<style lang="scss" scoped>
@import "@css/style.scss";
@media print {
  .no-print {
    background-color: white !important;;
  }
}
.warning {
    font-weight: 400;
    color: #FFFFFF;
    font-size: rem(12);
    width: rem(335);
    height: rem(40);
    background: #F39920;
    border-radius: rem(10) rem(10) rem(10) rem(10);
    opacity: 1;
    align-items: center;
    justify-content: space-between;
    display: flex;
    &-content{
      
        margin-left: rem(20);
       
    }
    &-close{
        cursor: pointer;
        margin-right: rem(20);
        width:rem(20) ;
        height:rem(20) ;
    }
}
</style>