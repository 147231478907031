<template>
  <div class="chart-shell">
    <p class="chart-shell-title">{{ title }}：</p>
    <p class="chart-shell-info">{{ info }}</p>
    <slot class="chart-shell-content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    info: String,
  },
};
</script>

<style lang="scss" scoped>

.chart-shell {
  width: 100%;

  &-title {
    color: black;
    font-size: rem(14);
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: rem(20);
    margin-top: rem(20);
  }

  &-info {
    color: rgba($color: #000000, $alpha: 0.6);
    font-size: rem(12);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: rem(5);
    margin-left: rem(20);
  }

  &-content {
    width: 100%;
    height: rem(190);
    box-sizing: border-box;
    padding: 0 rem(25);
    margin-top: rem(10);
    margin-bottom: rem(10);
  }
}
</style>