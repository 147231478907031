<template>
  <div class="sleep-period">
    <div class="sleep-period-left">
      <sleep-period-title
        title2="持续时间"
        title3="总占比"
      ></sleep-period-title>
      <sleep-period-unit
        title="清醒期"
        :time="model.awakeTime"
        :ratio="model.awakeRatio"
      ></sleep-period-unit>
      <sleep-period-unit
        title="眼动期"
        :time="model.remTime"
        :ratio="model.remRatio"
      ></sleep-period-unit>
      <sleep-period-unit
        title="浅睡期"
        :time="model.lightSleepTime"
        :ratio="model.lightSleepRatio"
      ></sleep-period-unit>
      <sleep-period-unit
        title="深睡期"
        :time="model.deepSleepTime"
        :ratio="model.deepSleepRatio"
      ></sleep-period-unit>
    </div>
    <sleep-period-pie
      class="sleep-period-right"
      :dataArray="model.pieArray"
    ></sleep-period-pie>
  </div>
</template>

<script>
import SleepPeriodUnit from "./sleep-period-unit.vue";
import SleepPeriodTitle from "./sleep-period-title.vue";
import SleepPeriodPie from "./sleep-period-pie.vue";
export default {
  components: {
    SleepPeriodTitle,
    SleepPeriodUnit,
    SleepPeriodPie,
  },
  props: {
    model: Object,
  },
};
</script>

<style lang="scss" scoped>

.sleep-period {
  width: 100%;
  box-sizing: border-box;
  padding: rem(10) rem(20);
  display: flex;
  justify-content: space-between;

  &-left {
    width: 40%;
  }

  &-right {
    width: 50%;
  }
}
</style>