<template>
  <div class="sleep-event">
    <sleep-event-unit title="平均暂停和低通气时间" :value="model.averageApneaTime" unit="秒"></sleep-event-unit>
    <sleep-event-unit title="最长暂停和低通气时间" :value="model.maxApneaTime" :tip="model.maxApneaTip"
      unit="秒"></sleep-event-unit>
    <sleep-event-unit title="总呼吸事件数" :value="model.breathEventCount" unit="次"></sleep-event-unit>
    <sleep-event-unit title="总呼吸事件时间" :value="model.breathEventTime" unit="分钟"></sleep-event-unit>
    <sleep-event-unit title="占总记录时间" :value="model.breathEventRate" unit="%"></sleep-event-unit>
    <div>
      <div v-if="model.isSeprateDisplayBEOH">
        <sleep-event-unit title="阻塞事件数" :value="model.beoCnt" unit="次"></sleep-event-unit>
        <sleep-event-unit title="低通气事件数" :value="model.behCnt" unit="次"></sleep-event-unit>
      </div>
      <sleep-event-unit v-else title="阻塞及低通气事件数" :value="model.beohCnt" unit="次"></sleep-event-unit>
    </div>
    <sleep-event-unit title="中枢性呼吸事件次数" :value="model.csaCount" unit="次"></sleep-event-unit>
    <sleep-event-unit title="混合性呼吸事件次数" :value="model.msaCount" unit="次"></sleep-event-unit>
  </div>
</template>

<script>
import SleepEventUnit from "@c/report-detail/sleep-event/sleep-event-unit.vue";
export default {
  components: {
    SleepEventUnit,
  },
  props: {
    model: Object,
  },
};
</script>

<style lang="scss" scoped>


.sleep-event {
  width: 100%;
  box-sizing: border-box;
  padding: rem(10) rem(20);
}
</style>