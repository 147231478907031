<template>
    <div>
        <!-- 北京大学深圳医院呼吸内科睡眠检测中心 -->
        <div v-if="model.reportTemplateType == 4">
            <div class="title">
                <div class="title-operate noPrint">
                    <div class="title-operate-save" @click="onSaveClick">保存</div>
                    <div class="title-operate-pdf" @click="onDownloadPDFClick">下载PDF</div>
                    <div class="title-operate-png" @click="onDownloadPNGClick">下载PNG</div>
                    <div class="title-operate-print" @click="onPrintClick">打印</div>
                    <div class="title-operate-share" @click="onShareClick">分享</div>
                </div>
                <img class="title-head" src="@imgs/bjsztitle.png" />
                <compression-info-bjsz :model="model" :patientBMI="patientBMI" @onInputInput="onInputInput" />
            </div>
            <compression-data-bjsz :model="model" />
            <compression-suggestion :model="model" @onEditorSaveClick="onEditorSaveClick" />
        </div>
        <!-- 深圳市儿童医院 -->
        <div v-if="model.reportTemplateType == 12">
            <div class="title">
                <div class="title-operate-blue noPrint">
                    <div class="title-operate-blue-item" @click="onSaveClick">保存</div>
                    <div class="title-operate-blue-item" @click="onShareClick">分享</div>
                    <div class="title-operate-blue-item" @click="onDownloadPDFClick">下载PDF</div>
                    <div class="title-operate-blue-item" @click="onDownloadPNGClick">下载PNG</div>
                    <div class="title-operate-blue-item" @click="onPrintClick">打印</div>
                </div>
                <div class="title-report-title">
                    <img class="title-report-title-icon" :src="model.hospitalIcon" />
                    <div class="title-report-title-name">深圳市儿童医院耳鼻咽喉头颈外科睡眠监测报告</div>
                </div>
                <compression-info :model="model" :patientBMI="patientBMI" @onInputInput="onInputInput" />
            </div>
            <compression-data :model="model" />
            <compression-chart :model="model" />
            <compression-suggestion :model="model" @onEditorSaveClick="onEditorSaveClick" />
        </div>
    </div>
</template>

<script>
import compressionData from '@c/report-detail/report-compression/compression-data/compression-data.vue';
import compressionDataBjsz from '@c/report-detail/report-compression/compression-data/compression-data-bjsz2.vue';
import compressionChart from '@c/report-detail/report-compression/compression-chart/compression-chart.vue';
import compressionSuggestion from '@c/report-detail/report-compression/compression-suggestion/compression-suggestion.vue';
import compressionInfo from '@c/report-detail/report-compression/comression-info/compression-info.vue';
import compressionInfoBjsz from '@c/report-detail/report-compression/comression-info/compression-info-bjsz.vue';
export default {

    props: {
        model: Object,
        patientBMI: String,
    },
    data() {
        return {

        }
    },

    components: {
        compressionData,
        compressionChart,
        compressionSuggestion,
        // compressionTitle,
        compressionDataBjsz,
        compressionInfo,
        compressionInfoBjsz,
    },




    methods: {
        onInputInput(title, value) {
            this.$emit("onInputInput", title, value);
        },
        onSaveClick() {
            this.$emit("onSaveClick");
        },
        onEditorSaveClick(html) {
            this.$emit("onEditorSaveClick", html);
        },
        onDownloadPDFClick() {
            this.$emit("onDownloadPDFClick");
        },
        onDownloadPNGClick() {
            this.$emit("onDownloadPNGClick");
        },
        onPrintClick() {
            this.$emit("onPrintClick");
        },
        onShareClick() {
            this.$emit("onShareClick");
        },


    },

};
</script>

<style lang="scss" scoped>
.title {
    width: 100%;
    font-family: "songti";
    color: #262626;

    &-report-title {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-top: 10px;

        &-icon {
            width: 70px;
        }

        &-name {
            font-size: rem(24);
            font-weight: bold;
            line-height: 40px;
            text-align: center;
            margin-left: 7px;
        }

    }

    &-operate-blue {
        display: flex;
        width: rem(280);
        color: #325FAC;
        font-size: rem(18);
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: auto;
        align-self: flex-end;
        // justify-content: space-between;

        &-item {
            color: white;
            background-color: #169bd5;
            font-size: 12px;
            border-radius: 4px;
            width: 58px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            cursor: pointer
        }
    }

    &-operate {
        display: flex;
        width: rem(332);
        color: #325FAC;
        font-size: rem(18);
        font-weight: bold;
        margin-left: auto;
        align-self: flex-end;
        justify-content: space-between;

        &-save {
            cursor: pointer
        }

        &-pdf {
            cursor: pointer
        }

        &-png {
            cursor: pointer
        }

        &-print {
            cursor: pointer
        }

        &-share {
            cursor: pointer
        }
    }




    &-head {
        margin-left: rem(40);
        align-items: center;
        width: rem(636);
        height: rem(60);

    }

}

@media print {
    .noPrint {
        display: none;
        background-color: white !important;
    }

    .title-head {
        top: rem(0);
    }
}
</style>