<template>
    <div class="title">
        <!-- <div class="title-content1">
            <div class="title-content1-report">
                <p class="title-content1-title">报告：</p>
                <input class="title-content1-input" type="text" v-model="operator"
                    @input="onInputInput('报告', operator)" />
            </div>
            <div class="title-content1-review">
                <p class="title-content1-title">审核：</p>
                <input class="title-content1-input" type="text" v-model="reviewer"
                    @input="onInputInput('审核', reviewer)" />
            </div>
        </div> -->
        <div class="title-content2">
            <div class="title-content2-name">
                <p class="title-content2-title">姓名：</p>
                <input class="title-content2-input" type="text" v-model="trueName"
                    @input="onInputInput('姓名', trueName)" />

            </div>
            <div class="title-content2-height">
                <p class="title-content2-title">身高(cm)：</p>
                <input class="title-content2-input" type="text" v-model="height"
                    @input="onInputInput('身高(cm)', height)" />

            </div>
            <div class="title-content2-age">
                <p class="title-content2-title">年龄：</p>
                <input class="title-content2-input" type="text" v-model="age" @input="onInputInput('年龄', age)" />
            </div>
            <div class="title-content2-records">
                <p class="title-content2-title">门诊号：</p>
                <input class="title-content2-records-input" type="text" v-model="mzh"
                    @input="onInputInput('病案号', mzh)" />
            </div>

        </div>
        <div class="title-content3">
            <div class="title-content3-sex">
                <p class="title-content3-title">性别：</p>
                <input class="title-content3-input" type="text" v-model="sex" @input="onInputInput('性别', sex)" />
            </div>
            <div class="title-content3-weight">
                <p class="title-content3-title">体重(kg)：</p>
                <input class="title-content3-input" type="text" v-model="weight"
                    @input="onInputInput('体重(Kg)', weight)" />
            </div>
            <div class="title-content3-bmi">
                <p class="title-content3-title">BMI：</p>
                <p class="title-content3-bmi-bmi">{{ patientBMI }}</p>
            </div>
            <div class="title-content3-department">
                <p class="title-content3-title">科室：</p>
                <input class="title-content3-input" style="width: 170px;" type="text" v-model="department"
                    @input="onInputInput('科室', department)" />
            </div>

        </div>
    </div>

</template>

<script>
export default {
    props: {
        model: Object,
        patientBMI: String,
    },
    data() {
        return {
            trueName: "",
            operator: "",
            reviewer: "",
            height: "",
            age: "",
            mzh: "",
            sex: "",
            weight: "",
            bmi: "",
            department: "",
            mobile: ""
        }
    },

    mounted() {
        this.updateData(this.model)
    },

    methods: {
        onInputInput(title, value) {
            this.$emit("onInputInput", title, value);
        },
        updateData(model) {
            this.trueName = model.initialPatientName;
            this.operator = model.initialOperator;
            this.reviewer = model.initialReviewer;
            this.height = model.initialPatientHeight;
            this.age = model.initialPatientAge;
            this.mzh = model.initialPatientDiagnoseNumber;
            this.sex = model.initialPatientGender;
            this.weight = model.initialPatientWeight;
            this.department = model.initialPatientDepartment;
            this.mobile = model.initInitialMobile;
        }
    },
    watch: {
        model(newValue) {
            this.updateData(newValue);
        },
    },

};
</script>

<style lang="scss" scoped>
.title {
    &-content1 {
        margin-top: rem(10);
        font-size: rem(16);
        font-weight: bold;
        display: flex;

        &-input {
            font-size: rem(16);
            font-family: "songti";
            font-weight: bold;
            width: rem(91);
        }

        &-title {
            display: flex;
            align-items: center;
        }

        &-report {

            display: flex;

        }

        &-review {

            display: flex;
            margin-left: rem(10);
        }

        &-date {
            // text-align: center;
            /*水平居中*/
            display: flex;
            align-items: center;
            margin-left: auto;

        }


    }

    &-content2 {
        margin-top: rem(15);
        font-size: rem(16);
        font-weight: bold;
        display: flex;

        &-input {
            font-size: rem(16);
            font-family: "songti";
            font-weight: bold;
            width: rem(100);
        }

        &-title {
            display: flex;
            align-items: center;
            flex-shrink: 0;
        }

        &-name {

            display: flex;
        }

        &-height {
            margin-left: rem(10);
            display: flex;
        }

        &-age {
            margin-left: rem(10);
            display: flex;
        }

        &-records {

            display: flex;

            &-input {
                font-size: rem(16);
                font-family: "songti";
                font-weight: bold;
                width: rem(168);
            }
        }

    }

    &-content3 {
        margin-top: rem(5);
        font-size: rem(16);
        font-weight: bold;
        display: flex;

        &-input {
            font-size: rem(16);
            font-family: "songti";
            font-weight: bold;
            width: rem(100);
        }

        &-title {
            display: flex;
            align-items: center;
            flex-shrink: 0;
        }

        &-sex {
            display: flex;
        }

        &-weight {
            margin-left: rem(5);
            display: flex;
        }

        &-bmi {
            margin-left: rem(5);
            display: flex;

            &-bmi {
                display: flex;
                align-items: center;
                width: rem(101);
            }
        }

        &-department {
            display: flex;
        }
    }
}
</style>
