<template>
  <div class="editor">
    <div id="toobar" class="editor-bar"></div>
    <!-- 四川省中医院 -->
    <div id="editor" class="editor-content" :class="{ 'reduce-line-height': model.reportTemplateType === 5 }"></div>
    <button v-if="isEditable" class="editor-save noPrint" style="z-index: 100001;" data-html2canvas-ignore="true"
      @click="onSaveClick">
      保存
    </button>
  </div>
</template>

<script>
import wangEditor from "wangeditor";
export default {
  props: {
    model: Object
  },

  data() {
    return {
      html: "",
      isEditable: false,
    };
  },

  mounted() {
    this.html = this.model.conclusion;
    this.isEditable = this.model.isEditable
    console.log("⛄️",this.model.isEditable)
    const editor = new wangEditor("#toobar", "#editor");
    editor.config.menus = []; //去掉编辑功能
    editor.config.onchange = (newHtml) => {
      this.html = newHtml;
    };
    editor.config.focus = false //不要focus
    editor.create();
    if (!this.isEditable) {
      //设置可编辑
      editor.disable();
    }
    editor.txt.html(this.html); //设置初始值


  },

  methods: {
    onSaveClick() {
      this.$emit("onSaveClick", this.html);
    },
  },
};
</script>

<style lang="scss" scoped>
.reduce-line-height {
  width: 100%;
  height: auto;
  border: 1px solid #eeeeee;

  ::v-deep .w-e-text-container p {
    line-height: 1;
  }
}

.editor {
  width: 100%;
  height: auto;
  margin-top: rem(40);
  position: relative;

  &-content {
    width: 100%;
    height: auto;
    border: 1px solid #eeeeee;
  }

  &-save {
    z-index: 2000;
    color: white;
    background-color: #169bd5;
    font-size: rem(12);
    border-radius: rem(4);
    width: rem(60);
    height: rem(25);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: rem(15);
    right: rem(20);
  }
}

@media print {
  .noPrint {
    display: none !important;
  }
}
</style>