<template>
  <div class="ahi-bar">
    <div class="ahi-bar-left">
      <p>{{ ahiTitle }}指数：</p>
      <input class="ahi-bar-left-value" :value="ahiValue" />
    </div>
    <div class="ahi-bar-right">
      <div class="ahi-bar-right-triangle" :style="triangleStyleObject" v-if="showAhiBarTriangle"></div>
      <div class="ahi-bar-right-top">
        <div :style="greenBarStyleObject"></div>
        <div :style="yellowBarStyleObject"></div>
        <div :style="orangeBarStyleObject"></div>
        <div :style="redBarStyleObject"></div>
      </div>
      <div class="ahi-bar-right-bottom">
        <p class="ahi-bar-right-bottom-number">0</p>
        <p class="ahi-bar-right-bottom-text">正常</p>
        <p class="ahi-bar-right-bottom-number">{{ degreeArray[0] }}</p>
        <p class="ahi-bar-right-bottom-text">轻度</p>
        <p class="ahi-bar-right-bottom-number">{{ degreeArray[1] }}</p>
        <p class="ahi-bar-right-bottom-text">中度</p>
        <p class="ahi-bar-right-bottom-number">{{ degreeArray[2] }}</p>
        <p class="ahi-bar-right-bottom-text">重度</p>
        <p class="ahi-bar-right-bottom-number">∞</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ahiTitle: String,
    ahi: String,
    degreeArray: Array,
  },

  data() {
    return {
      degreeStyleArray: [
        { width: 23.5, color: "#16d585" },
        { width: 23.5, color: "#EDF200" },
        { width: 24, color: "#F28300" },
        { width: 28, color: "#F2001D" },
      ],
    };
  },

  computed: {
    ahiValue: function() {
    const ahiValue = parseFloat(this.ahi);
    if (isNaN(ahiValue) || ahiValue < 0) {
      return "";
    } else {
      return this.ahi;
    }
  },
    showAhiBarTriangle() {
      // if (this.ahi === "") {
      //   return false;
      // }
      const ahiValue = parseFloat(this.ahi);
      return !isNaN(ahiValue) && ahiValue >= 0;
    },

    greenBarStyleObject() {
      return {
        width: `${this.degreeStyleArray[0].width}%`,
        backgroundColor: this.degreeStyleArray[0].color,
      };
    },

    yellowBarStyleObject() {
      return {
        width: `${this.degreeStyleArray[1].width}%`,
        backgroundColor: this.degreeStyleArray[1].color,
      };
    },

    orangeBarStyleObject() {
      return {
        width: `${this.degreeStyleArray[2].width}%`,
        backgroundColor: this.degreeStyleArray[2].color,
      };
    },

    redBarStyleObject() {
      return {
        width: `${this.degreeStyleArray[3].width}%`,
        backgroundColor: this.degreeStyleArray[3].color,
      };
    },

    triangleStyleObject() {
      if (this.ahi === "") {
        return {
          visibility: "hidden",
        };
      } else {
    
        for (let index = 0; index < this.degreeArray.length; index++) {
          const item = this.degreeArray[index];
          var value = Number.parseFloat(this.ahi);
          if(value > 100){
            value = 100
          }
          if (value <= item) {
            let width = 0;
            for (let i = 0; i < index; i++) {
              width += this.degreeStyleArray[i].width;
            }
            //超过的宽度
            const formerItem = index == 0 ? 0 : this.degreeArray[index - 1];
            const valueDifference = value - formerItem;
            const itemDifference = item - formerItem;
            const widthRate = valueDifference / itemDifference;
            // console.log("formerItem",formerItem);
            // console.log("valueDifference",valueDifference);
            // console.log("itemDifference", itemDifference);
            // console.log("widthRate",widthRate);
            // console.log("width1",width);
            width += this.degreeStyleArray[index].width * widthRate;
            // console.log("width2",width);
            return {

              marginLeft: `${width - 1.5}%`, //三角形顶点对齐
              borderTopColor: this.degreeStyleArray[index].color,

            };
          }

          continue;
        }
      }

    },
  },

  
};
</script>



<style lang="scss" scoped>
.ahi-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: rem(20);

  &-left {
    color: black;
    font-weight: bold;
    font-size: rem(14);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-value {
      width: rem(45);
    }
  }

  &-right {
    width: 50%;
    height: rem(30);

    &-triangle {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: rem(12) solid;
      border-left: rem(6) solid transparent;
      border-right: rem(6) solid transparent;
    }

    &-top {
      display: flex;
      width: 100%;
      height: rem(10);

      div {
        height: 100%;
      }
    }

    &-bottom {
      color: #333333;
      font-size: rem(14);
      width: 96%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: rem(10);


      &-number {
        color: #999999;
        font-size: rem(12);
        height: rem(25);
      }

      &-text {
        color: #333333;
        font-size: rem(14);
      }
    }
  }
}
</style>