<template>
  <div class="sleep-period-unit">
    <p class="sleep-period-unit-title">{{ title }}：</p>
    <div class="sleep-period-unit-unit">
      <input class="sleep-period-unit-unit-value" :value="time" />
      <p>分钟</p>
    </div>
    <div class="sleep-period-unit-unit">
      <input class="sleep-period-unit-unit-value" :value="ratio" />
      <p v-show="ratio">%</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    time: String,
    ratio: String,
  },
};
</script>

<style lang="scss" scoped>
.sleep-period-unit {
  color: black;
  font-size: rem(14);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(30);

  &-unit {
    min-width: 33%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-value {
      text-align: right;
      width: rem(50);
      margin-right: rem(15);

    }
  }
}
</style>