<template>
  <div class="data-abstract-title">
    <p class="data-abstract-title-item left">{{ title1 }}</p>
    <p class="data-abstract-title-item middle">{{ title2 }}</p>
    <p class="data-abstract-title-item right">{{ title3 }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title1: String,
    title2: String,
    title3: String,
  },
};
</script>

<style lang="scss" scoped>

.data-abstract-title {
  color: black;
  font-size: rem(14);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(30);

  &-item {
    width: 33.3%;
    display: flex;
    align-items: center;
  }

  .left {
    justify-content: flex-start;
  }

  .middle {
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
  }
}
</style>