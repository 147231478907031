<template>
    <div class="suggestion">
        <!-- 北京大学深圳医院呼吸内科睡眠检测中心 -->
        <div v-if="model.reportTemplateType == 4">
            <div class="suggestion-solid"></div>
            <div class="suggestion-editor suggestion-editor-content-wide">
                <div id="toobar" class="suggestion-editor-bar"></div>
                <div id="editor" class="suggestion-editor-content suggestion-editor-content-wide" v-html="html"
                    contenteditable="true"></div>
                <button class="suggestion-editor-save z-index-top noPrint" data-html2canvas-ignore="true"
                    @click="onEditorSaveClick">
                    保存
                </button>
            </div>
            <div class="suggestion-hint">
                <p>监测包含呼吸、胸腹运动、血氧饱和度等指标；此监测结果只对本次监测负责。 </p>
                <p>北京大学深圳医院呼吸内科睡眠监测中心 Tel：83923333 转 2160</p>
            </div>
        </div>
        <!-- 深圳市儿童医院 -->
        <div v-if="model.reportTemplateType == 12">
            <div class="suggestion-head">
                <div class="suggestion-head-ahi">
                    <div>{{ ahiTitle }}指数：</div>
                    <input type="text" :value="ahi" class="suggestion-head-ahi-value">
                </div>
                <div class="suggestion-head-grading">
                    <div class="suggestion-head-grading-triangle" v-if="showAhiBarTriangle"
                        :style="triangleStyleObject">
                    </div>
                    <div class="suggestion-head-grading-line1"></div>
                    <div class="suggestion-head-grading-line2"></div>
                    <div class="suggestion-head-grading-line3"></div>
                    <div class="suggestion-head-grading-line4"></div>
                    <div class="suggestion-head-grading-line5"></div>
                    <div class="suggestion-head-grading-line"></div>
                    <div class="suggestion-head-grading-unit0">0</div>
                    <div class="suggestion-head-grading-unit1">正常</div>
                    <div class="suggestion-head-grading-unit2">{{ ahiDegreeArray[0] }}</div>
                    <div class="suggestion-head-grading-unit3">轻度</div>
                    <div class="suggestion-head-grading-unit4">{{ ahiDegreeArray[1] }}</div>
                    <div class="suggestion-head-grading-unit5">中度</div>
                    <div class="suggestion-head-grading-unit6">{{ ahiDegreeArray[2] }}</div>
                    <div class="suggestion-head-grading-unit7">重度</div>
                    <div class="suggestion-head-grading-unit8">∞</div>
                </div>
            </div>
            <div class="suggestion-editor">
                <div id="toobar" class="suggestion-editor-bar"></div>
                <div id="editor" class="suggestion-editor-content" v-html="html" contenteditable="true"></div>
                <button class="suggestion-editor-save z-index-top noPrint" data-html2canvas-ignore="true"
                    @click="onEditorSaveClick">
                    保存
                </button>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        model: Object,
    },
    data() {
        return {
            context: {},
            ahi: "",
            html: "",
            ahiDegreeArray: [],
        }
    },
    methods: {

        onEditorSaveClick() {
            var html = document.getElementById('editor').innerHTML
            this.$emit("onEditorSaveClick", html);
        },


    },
    mounted() {

        if (this.model.ahi < 0) {
            this.ahi = ""
        } else {
            this.ahi = this.model.ahi
        }
        this.html = this.model.conclusion
        this.ahiDegreeArray = this.model.ahiDegreeArray
    },
    computed: {
        ahiTitle: function () {
            let title = ""
            if (this.model.reportModeType == DEVICE_MONITOR_MODE.儿童模式) {
                title = "OAHI";
            } else {
                title = "AHI";
            }
            return title;
        },
        ahiValue: function () {
            const ahiValue = parseFloat(this.model.ahi);
            if (isNaN(ahiValue) || ahiValue < 0) {
                return "";
            } else {
                return this.model.ahi;
            }
        },
        showAhiBarTriangle() {
            // if (this.ahi === "") {
            //   return false;
            // }
            const ahiValue = parseFloat(this.ahi);
            return !isNaN(ahiValue) && ahiValue >= 0;
        },
        triangleStyleObject() {
            if (this.model.ahi === "") {
                return {
                    visibility: "hidden",
                };
            } else {

                for (let index = 0; index < this.ahiDegreeArray.length; index++) {
                    const item = this.ahiDegreeArray[index];
                    var value = Number.parseFloat(this.model.ahi);
                    if (value > 100) {
                        value = 100
                    }

                    if (value <= item) {
                        let width = 0;
                        let multiple = 0;
                        // for (let i = 0; i < index; i++) {
                        //     width += this.degreeStyleArray[i].width;
                        // }
                        //超过的宽度
                        const formerItem = index == 0 ? 0 : this.ahiDegreeArray[index - 1];
                        if (formerItem == this.ahiDegreeArray[0]) {
                            multiple = 1
                        } else if (formerItem == this.ahiDegreeArray[1]) {
                            multiple = 2
                        } else if (formerItem == this.ahiDegreeArray[2]) {
                            multiple = 3
                        } else {
                            multiple = 0
                        }
                        const valueDifference = value - formerItem;
                        const itemDifference = item - formerItem;
                        const widthRate = valueDifference / itemDifference;
                        // console.log("formerItem", formerItem);
                        // console.log("valueDifference", valueDifference);
                        // console.log("itemDifference", itemDifference);
                        // console.log("widthRate", widthRate);
                        // console.log("multiple", multiple);
                        width = 50 * widthRate + 50 * multiple
                        // width += 50 * widthRate;
                        // console.log("width2", width);
                        // console.log("marginleft", `${width - 1.5}%`);
                        return {

                            marginLeft: `${width}px`, //三角形顶点对齐

                        };
                    }

                    continue;
                }
            }

        },
    },

    watch: {
        model(newValue) {
            this.html = newValue.conclusion
            this.ahi = newValue.ahi
            this.ahiDegreeArray = newValue.ahiDegreeArray
        }
    }
};
</script>

<style lang="scss" scoped>
.suggestion {
    width: 100%;
    font-family: "songti";
    color: #262626;
    padding-bottom: 60px;

    &-solid {
        margin-top: rem(5);
        width: 100%;
        height: 0px;
        border: 1px solid #000000;
        border-style: dashed;
    }

    &-head {
        display: flex;
        width: 100%;
        font-size: rem(16);
        justify-content: space-between;

        &-ahi {
            display: flex;
            font-weight: bold;
            padding-top: rem(14);
            align-items: center;

            &-value {
                font-size: rem(16);
                font-weight: bold;
                font-family: "songti";
            }
        }

        &-grading {
            font-size: rem(12);
            display: flex;
            width: rem(203);

            &-triangle {
                position: absolute;
                display: inline-block;
                width: 0;
                height: 0;
                margin-top: rem(14);
                border-top: rem(5) solid #000000;
                border-left: rem(3) solid transparent;
                border-right: rem(3) solid transparent;
                border-bottom: rem(0) solid #000000;
            }

            &-line {
                margin-left: rem(3);
                margin-top: rem(23);
                width: rem(200);
                height: 1px;
                border-top: solid #000000 1px;
            }

            &-line1 {
                margin-top: rem(21);
                margin-left: rem(3);
                position: absolute;
                width: rem(1);
                height: rem(4);
                border-left: solid #000000 1px;
            }

            &-line2 {
                margin-top: rem(21);
                margin-left: rem(53);
                position: absolute;
                width: rem(1);
                height: rem(4);
                border-left: solid #000000 1px;
            }

            &-line3 {
                margin-top: rem(21);
                margin-left: rem(103);
                position: absolute;
                width: rem(1);
                height: rem(4);
                border-left: solid #000000 1px;
            }

            &-line4 {
                margin-top: rem(21);
                margin-left: rem(153);
                position: absolute;
                width: rem(1);
                height: rem(4);
                border-left: solid #000000 1px;
            }

            &-line5 {
                margin-top: rem(21);
                margin-left: rem(203);
                position: absolute;
                width: rem(1);
                height: rem(4);
                border-left: solid #000000 1px;
            }

            &-unit0 {
                position: absolute;
                margin-top: rem(27);
            }

            &-unit1 {
                position: absolute;
                margin-left: rem(16);
                margin-top: rem(27);
            }

            &-unit2 {
                position: absolute;
                margin-top: rem(27);
                margin-left: rem(50);
            }

            &-unit3 {
                position: absolute;
                margin-top: rem(27);
                margin-left: rem(66);
            }

            &-unit4 {
                position: absolute;
                margin-top: rem(27);
                margin-left: rem(97);
            }

            &-unit5 {
                position: absolute;
                margin-top: rem(27);
                margin-left: rem(116);
            }

            &-unit6 {
                position: absolute;
                margin-top: rem(27);
                margin-left: rem(147);
            }

            &-unit7 {
                position: absolute;
                margin-top: rem(27);
                margin-left: rem(166);
            }

            &-unit8 {
                position: absolute;
                margin-top: rem(27);
                margin-left: rem(196);
            }

        }


    }

    &-editor {
        width: 100%;
        height: auto;
        margin-top: rem(20);
        position: relative;

        &-content {
            width: 100%;
            height: auto;
            min-height: rem(30);
            color: black;
            font-size: rem(12);

        }

        &-content-wide {
            width: 100%;
            height: rem(259);
            min-height: rem(30);
            color: black;
            font-size: rem(14);

        }

        &-save {
            color: white;
            background-color: #169bd5;
            font-size: rem(12);
            border-radius: rem(3);
            width: rem(50);
            height: rem(22);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: rem(10);
        }
    }



    &-hint {
        color: #000000;
        font-size: rem(12);
        width: 100%;

        >p {
            display: flex;
            justify-content: center;
        }
    }
}


[contenteditable]:focus {
    outline: none;
}


@media print {
    .noPrint {
        display: none !important;
    }
}
</style>