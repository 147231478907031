<template>
    <report-section title="" class="chart">
        <template v-slot:content>
            <line-chart title="睡眠血氧趋势" :dataArray="model.bloodOxygenChartDataArray"
                :timeTickArray="model.timeTickArray"></line-chart>
            <line-chart title="睡眠脉率趋势" :dataArray="model.pulseChartDataArray"
                :timeTickArray="model.timeTickArray"></line-chart>
            <area-chart title="睡眠体动幅度" :dataArray="model.moveRangeChartDataArray" :timeTickArray="model.timeTickArray"
                :sn="model.ringSN"></area-chart>
        </template>
    </report-section>
</template>

<script>

import ReportSection from "@c/report-detail/single-blood-oxygen-report/common/report-section.vue";
import LineChart from "@c/report-detail/report-compression/compression-chart/compression-line-chart.vue";
import AreaChart from "@c/report-detail/report-compression/compression-chart/compression-area-chart.vue";
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        ReportSection,
        LineChart,
        AreaChart,
    },
    props: {
        model: Object,
    }
};
</script>

<style lang="scss" scoped>
.chart {
    margin-top: 10px;
    transform: translateZ(0);

}
</style>