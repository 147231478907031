class SleepChartTool {
    static sleepPeriodStatusToStr(statusCode) {
        switch (statusCode) {
            case "0":
                return "";
            case "1":
                return "深睡";
            case "2":
                return "浅睡";
            case "3":
                return "眼动";
            case "4":
                return "清醒";
        }
    }
}

export {
    SleepChartTool
}