<template>
    <div class="data">
        <div class="data-line-horizoltal" />
        <div class="data-detail">
            <div class="data-detail-column data-detail-column-data ">
                <p class="data-detail-column-title">数据摘要</p>
                <div class="data-detail-column-content">
                    <div class="data-detail-column-content-title">
                        <p>开始记录时间：</p>
                        <p>结束记录时间：</p>
                        <p>开始入睡时间：</p>
                        <p>结束睡眠时间：</p>
                        <p>总睡眠时长(TST)：</p>
                        <p>睡眠效率：</p>
                        <p>平均脉率：</p>
                        <p>最大脉率：</p>
                        <p>最小脉率：</p>
                        <p>最高呼吸率：</p>
                        <p>最低呼吸率：</p>
                    </div>
                    <div class="data-detail-column-content-data">
                        <data-unit :value="this.model.startRecordTime" :title="'开始记录时间'" />
                        <data-unit :value="this.model.stopRecordTime" :title="'结束记录时间'" />
                        <data-unit :value="this.model.startSleepTime" :title="'开始入睡时间'" />
                        <data-unit :value="this.model.stopSleepTime" :title="'结束睡眠时间'" />
                        <data-unit :value="this.model.sleepTime" :title="'总睡眠时长(TST)'" />
                        <data-unit :value="this.model.sleepEfficiency" :title="'睡眠效率'" />
                        <data-unit :value="this.model.averagePulse" :title="'平均脉率'" />
                        <data-unit :value="this.model.maxPulse" :title="'最大脉率'" />
                        <data-unit :value="this.model.minPulse" :title="'最小脉率'" />
                        <data-unit :value="this.model.maxBreathRate" :title="'最高呼吸率'" />
                        <data-unit :value="this.model.minBreathRate" :title="'最低呼吸率'" />
                    </div>
                    <div class="data-detail-column-content-unit">
                        <p>&nbsp</p>
                        <p>&nbsp</p>
                        <p>&nbsp</p>
                        <p>&nbsp</p>
                        <p>&nbsp</p>
                        <p>%</p>
                        <p>bpm</p>
                        <p>bpm</p>
                        <p>bpm</p>
                        <p>bpm</p>
                        <p>bpm</p>
                    </div>
                </div>
            </div>
            <div class="data-detail-line" />
            <div class="data-detail-column data-detail-column-breath ">
                <p class="data-detail-column-title">呼吸事件</p>
                <div class="data-detail-column-content">
                    <div class="data-detail-column-content-title">
                        <p>{{ this.ahiTitle }}指数：</p>
                        <p>氧减指数(ODI3)：</p>
                        <p>阻塞及低通气事件数：</p>
                        <p>中枢性呼吸事件次数：</p>
                        <p>混合性呼吸事件次数：</p>
                        <p>总呼吸事件数：</p>
                        <p>总呼吸事件时间：</p>
                        <p>占总记录时间：</p>
                        <p>平均暂停和低通气时间：</p>
                        <p>最长暂停和低通气时间：</p>
                        <p>最长暂停和低通气发生于：</p>
                    </div>
                    <div class="data-detail-column-content-data">
                        <data-unit :value="this.model.ahi" :title="'AHI指数'" />
                        <data-unit :value="this.model.oxygenRadiationIndex" :title="'氧减指数(ODI3)'" />
                        <data-unit :value="this.model.beohCnt" :title="'阻塞及低通气事件数'" />
                        <data-unit :value="this.model.csaCount" :title="'中枢性呼吸事件次数'" />
                        <data-unit :value="this.model.msaCount" :title="'混合性呼吸事件次数'" />
                        <data-unit :value="this.model.breathEventCount" :title="'总呼吸事件数'" />
                        <data-unit :value="this.model.breathEventTime" :title="'总呼吸事件时间'" />
                        <data-unit :value="this.model.breathEventRate" :title="'占总记录时间'" />
                        <data-unit :value="this.model.averageApneaTime" :title="'平均暂停和低通气时间'" />
                        <data-unit :value="this.model.maxApneaTime" :title="'最长暂停和低通气时间'" />
                        <data-unit :value="this.model.maxApneaTip" :title="'最长暂停和低通气发生于'" />

                    </div>
                    <div class="data-detail-column-content-unit">
                        <p>次/小时</p>
                        <p>次/小时</p>
                        <p>次</p>
                        <p>次</p>
                        <p>次</p>
                        <p>次</p>
                        <p>分钟</p>
                        <p>%</p>
                        <p>秒</p>
                        <p>秒</p>
                        <p>&nbsp</p>
                    </div>
                </div>
            </div>
            <div class="data-detail-line" />
            <div class="data-detail-column data-detail-column-blood">
                <p class="data-detail-column-title">血氧统计</p>
                <div class="data-detail-column-content">
                    <div class="data-detail-column-content-title">
                        <p>平均血氧饱和度：</p>
                        <p>最低血氧饱和度：</p>
                        <p>氧减次数：</p>
                        <p>血氧&lt95%持续时间：</p>
                        <p>血氧&lt95%占比：</p>
                        <p>血氧&lt90%持续时间：</p>
                        <p>血氧&lt90%占比：</p>
                        <p>血氧&lt85%持续时间：</p>
                        <p>血氧&lt85%占比：</p>
                        <p>血氧&lt80%持续时间：</p>
                        <p>血氧&lt80%占比：</p>
                    </div>
                    <div class="data-detail-column-content-data">
                        <data-unit :value="this.model.averageBloodOxygenSaturation" :title="'平均血氧饱和度'" />
                        <data-unit :value="this.model.minBloodOxygenSaturation" :title="'最低血氧饱和度'" />
                        <data-unit :value="this.model.oxygenRadiationTimes" :title="'氧减次数'" />
                        <data-unit :value="this.model.bloodOxygen95LastingTime" :title="'血氧<95持续时间'" />
                        <data-unit :value="this.model.bloodOxygen95Rate" :title="'血氧<95占比'" />
                        <data-unit :value="this.model.bloodOxygen90LastingTime" :title="'血氧<90持续时间'" />
                        <data-unit :value="this.model.bloodOxygen90Rate" :title="'血氧<90占比'" />
                        <data-unit :value="this.model.bloodOxygen85LastingTime" :title="'血氧<85持续时间'" />
                        <data-unit :value="this.model.bloodOxygen85Rate" :title="'血氧<85占比'" />
                        <data-unit :value="this.model.bloodOxygen80LastingTime" :title="'血氧<80持续时间'" />
                        <data-unit :value="this.model.bloodOxygen80Rate" :title="'血氧<80占比'" />

                    </div>
                    <div class="data-detail-column-content-unit">
                        <p>%</p>
                        <p>%</p>
                        <p>次</p>
                        <p>分钟</p>
                        <p>%</p>
                        <p>分钟</p>
                        <p>%</p>
                        <p>分钟</p>
                        <p>%</p>
                        <p>分钟</p>
                        <p>%</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-line-horizoltal" />
    </div>
</template>
<script>
import dataUnit from '@c/report-detail/report-compression/compression-data/data-unit.vue';
export default {
    props: {
        model: Object,
    },
    data() {
        return {
            blankUnit: " "
        }
    },
    components: {
        dataUnit,
    },

    computed: {
        ahiTitle: function () {
            let title = ""
            if (this.model.reportModeType == DEVICE_MONITOR_MODE.儿童模式) {
                title = "OAHI";
            } else {
                title = "AHI";
            }
            return title;
        },
    }

};
</script>

<style lang="scss" scoped>
.data {
    width: rem(716);
    margin-top: rem(10);
    font-size: rem(13);
    font-family: "songti";

    &-line-horizoltal {
        width: 100%;
        height: rem(2);
        background-color: #000000;
    }

    &-detail {
        display: flex;

        &-column {

            &-data {
                width: 240px;
            }

            &-breath {
                width: 266px;
                margin-left: 5px
            }

            &-blood{
                width: 217px;
                margin-left: 5px;

            }

            &-title {
                color: #262626;
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
                margin: 10px 0;
            }

            &-content {
                margin-top: rem(12);
                display: flex;

                p {
                    line-height: 20px;
                    margin-bottom: rem(5);
                }

                &-data {
                    margin-left: rem(5);
                }

                &-unit {
                    margin-left: rem(5);
                }
            }

        }

        &-line {
            width: rem(1);
            background-color: #000000;
        }
    }   
}
</style>