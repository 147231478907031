<template>
  <div class="data-abstract">
    <data-abstract-unit :title="ahiTitle" :value="model.ahi" :isHighLight="true"></data-abstract-unit>
    <data-abstract-unit v-if="model.isSeprateDisplayBEOH" title="AI指数" :value="model.ai"></data-abstract-unit>
    <data-abstract-unit title="最高呼吸率" :value="model.maxBreathRate"></data-abstract-unit>
    <data-abstract-unit v-if="model.isShowAverageBreathRate" title="平均呼吸率"
      :value="model.averageBreathRate"></data-abstract-unit>
    <data-abstract-unit title="最低呼吸率" :value="model.minBreathRate"></data-abstract-unit>
  </div>
</template>

<script>
import DataAbstractUnit from "./data-abstract-unit.vue";
export default {
  components: {
    DataAbstractUnit,
  },

  props: {
    model: Object,
  },

  computed: {
    ahiTitle() {
      return `${this.model.ahiTitle}指数`
    }
  }
};
</script>

<style lang="scss" scoped>
.data-abstract {
  width: 100%;
  box-sizing: border-box;
  padding: rem(10) rem(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>