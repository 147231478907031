<template>
  <div class="section-header">{{ title }}</div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>


.section-header {
  color: black;
  font-size: rem(14);
  font-weight: bold;
  width: 100%;
  height: rem(40);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}
</style>