<template>
  <div class="line-input">
    <p class="line-input-title">{{ title }}：</p>
    <input
      class="line-input-input"
      type="text"
      ref="input"
      v-model="value"
      :readonly="isReadOnly"
      @input="onInputInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    initalValue: String,
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      value: "",
    };
  },

  created: function () {
    this.value = this.initalValue;
  },

  watch: {
    initalValue() {
      this.value = this.initalValue; //为了BMI的实时变化
    },
  },

  methods: {
    onInputInput: function () {
      this.$emit("onInput", this.title, this.value);
    },
  },
};
</script>

<style lang="scss" scoped>


.line-input {
  background-color: white;
  box-sizing: border-box;
  padding-left: rem(20);
  display: flex;

  &-title {
    color: black;
    font-size: rem(14);
  }

  &-input {
    color: black;
    font-size: rem(14);
    border: none;
    outline: none;
    border-radius: unset;
    border-bottom: 1px solid black;
    flex: 1;
    width: 100%;
  }
}
</style>