<template>
  <div class="blood-oxygen">
    <div class="blood-oxygen-left">
      <blood-oxygen-unit title="平均血氧饱和度" :value="model.averageBloodOxygenSaturation" unit="%"></blood-oxygen-unit>
      <blood-oxygen-unit title="最低血氧饱和度" :value="model.minBloodOxygenSaturation" unit="%"></blood-oxygen-unit>
      <blood-oxygen-unit title="氧减次数" :value="model.oxygenRadiationTimes" unit="次"></blood-oxygen-unit>
      <blood-oxygen-unit title="氧减指数" :value="model.oxygenRadiationIndex" unit="次/小时"></blood-oxygen-unit>
    </div>
    <div class="blood-oxygen-devider"></div>
    <div class="blood-oxygen-right">
      <sleep-period-title class="blood-oxygen-right-title" title1="血氧范围" title2="持续时间"
        title3="总占比"></sleep-period-title>
      <sleep-period-unit title="90%～94%" :time="model.bloodOxygen95LastingTime"
        :ratio="model.bloodOxygen95Rate"></sleep-period-unit>
      <sleep-period-unit title="85%～89%" :time="model.bloodOxygen90LastingTime"
        :ratio="model.bloodOxygen90Rate"></sleep-period-unit>
      <sleep-period-unit title="80%～84%" :time="model.bloodOxygen85LastingTime"
        :ratio="model.bloodOxygen85Rate"></sleep-period-unit>
      <sleep-period-unit class="blood-oxygen-right-80" title="< 80%" :time="model.bloodOxygen80LastingTime"
        :ratio="model.bloodOxygen80Rate"></sleep-period-unit>
    </div>
  </div>
</template>

<script>
import SleepPeriodUnit from "@c/report-detail/sleep-period/sleep-period-unit.vue";
import SleepPeriodTitle from "@c/report-detail/sleep-period/sleep-period-title.vue";
import BloodOxygenUnit from "./blood-oxygen-unit.vue";
export default {
  components: {
    SleepPeriodTitle,
    SleepPeriodUnit,
    BloodOxygenUnit,
  },

  props: {
    model: Object,
  },

  data() {
    return {
      uid: undefined
    }
  },

};
</script>

<style lang="scss" scoped>
.blood-oxygen {
  width: 100%;
  box-sizing: border-box;
  padding: rem(20) rem(20);
  display: flex;
  justify-content: space-between;

  &-left {
    width: 40%;
    margin-top: rem(30);
  }

  &-devider {
    background-color: #eeeeee;
    width: rem(1);
  }

  &-right {
    width: 50%;

    &-title {
      /deep/.middle {
        position: relative;
        left: 10px;
      }
    }

    &-80 {
      /deep/.sleep-period-unit-title {
        width: 85px;
      }
    }
  }
}
</style>