<template>
  <div class="sleep-period-pie">
    <div class="sleep-period-pie-content" id="pie"></div>
  </div>
</template>

<script>
import { Pie } from "@antv/g2plot";
export default {
  props: {
    dataArray: Array,
  },

  // data() {
  //   return {
  //   };
  // },

  mounted() {
    this.renderChart();
  },

  methods: {
    renderChart() {
      let pie = new Pie("pie", {
        data: this.dataArray,
        angleField: "value",
        colorField: "type",
        color: ["#e83000", "#f2ec5d", "#00dfbf", "#003a6a"],
        radius: 0.6,
        padding: [0, -80, 30, 0],
        label: {
          type: "outer",
          content: (item) => {
            return `${item.value}分钟`;
          },
        },
        pieStyle: {
          strokeOpacity: 0,
        },
        tooltip: {
          formatter: (item) => {
            return { name: item.type, value: `${item.value}分钟` };
          },
        },
        legend: {
          layout: "horizontal",
          position: "bottom",
          itemSpacing: 10,
          offsetX: 38,
          itemName: {
            style: {
              fontSize: 12,
            },
          },
        },
        interactions: [{ type: "legend-filter", enable: false }],
      });
      pie.render();
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep-period-pie {
  &-content {
    width: 100%;
    height: 100%;
  }
}
</style>