<template>
    <div class="data">
        <div class="data-summary">
            <div class="data-summary-title">数据摘要</div>

            <div class="data-summary-table">
                <tbody>
                    <tr>
                        <td>开始时间</td>
                        <td>{{ this.model.startRecordFullTime }}</td>
                        <td>结束时间</td>
                        <td>{{ this.model.stopRecordFullTime }}</td>
                    </tr>
                    <tr>
                        <td>总睡眠时长（TST）</td>
                        <td>{{ this.model.sleepTime }}</td>
                        <td>睡眠效率</td>
                        <td>{{ this.model.sleepEfficiency }}</td>
                    </tr>

                    <tr>
                        <td>平均脉率</td>
                        <td>{{ this.model.averagePulse }}</td>
                        <td>最高呼吸率</td>
                        <td>{{ this.model.maxBreathRate }}</td>
                    </tr>

                    <tr>
                        <td>最大脉率</td>
                        <td>{{ this.model.maxPulse }}</td>
                        <td>最低呼吸率</td>
                        <td>{{ this.model.minBreathRate }}</td>
                    </tr>

                    <tr>
                        <td>最小脉率</td>
                        <td>{{ this.model.minPulse }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </div>
        </div>
        <div class="data-breath">
            <div class="data-breath-title">呼吸事件</div>

            <div class="data-breath-table">
                <tbody>
                    <tr>
                        <td style="font-weight: bold; color: #BC0024;">AHI指数</td>
                        <td style="font-weight: bold; color: #BC0024;">{{ this.model.ahi }}次/小时</td>
                        <td>总呼吸事件时间</td>
                        <td>{{ this.model.breathEventTime ? this.model.breathEventTime : 0 }}分钟</td>
                    </tr>
                    <tr>
                        <td>阻塞及低通气事件数</td>
                        <td>{{ this.model.beohCnt ? this.model.beohCnt : 0 }}次</td>
                        <td>占总记录时间</td>
                        <td>{{ this.model.breathEventRate ? this.model.breathEventRate : 0 }}%</td>
                    </tr>

                    <tr>
                        <td>中枢性呼吸事件次数</td>
                        <td>{{ this.model.csaCount ? this.model.csaCount : 0 }}次</td>
                        <td>平均暂停和低通气时间</td>
                        <td>{{ this.model.averageApneaTime ? this.model.averageApneaTime : 0 }}秒</td>
                    </tr>

                    <tr>
                        <td>混合性呼吸事件次数</td>
                        <td>{{ this.model.msaCount ? this.model.msaCount : 0 }}次</td>
                        <td>最长暂停和低通气时间</td>
                        <td>{{ this.model.maxApneaTime ? this.model.maxApneaTime : 0 }}秒</td>
                    </tr>

                    <tr>
                        <td>总呼吸事件数</td>
                        <td>{{ this.model.breathEventCount ? this.model.breathEventCount : 0 }}次</td>
                        <td>最长暂停和低通气发生于</td>
                        <td>{{ this.model.maxApneaTip.replace('该事件发生于：', '') }}</td>
                    </tr>
                </tbody>
            </div>
        </div>
        <div class="data-spo2">
            <div class="data-spo2-title">血氧统计</div>

            <div class="data-spo2-table">
                <tbody>
                    <tr>
                        <td ></td>
                        <td >{{ this.rate1 }}</td>
                        <td>{{ this.rate2 }}</td>
                        <td>{{ this.rate3 }}</td>
                        <td>{{ this.rate4 }}</td>
                        <td>{{ this.rate5 }}</td>
                        
                    </tr>
                    <tr>
                        <td>总时长（分钟）</td>
                        <td>{{ this.model.bloodOxygen80LastingTime }}</td>
                        <td>{{ this.model.bloodOxygen85LastingTime - this.model.bloodOxygen80LastingTime}}</td>
                        <td>{{ this.model.bloodOxygen90LastingTime - this.model.bloodOxygen85LastingTime}}</td>
                        <td>{{ this.model.bloodOxygen95LastingTime - this.model.bloodOxygen90LastingTime}}</td>
                        <td>{{ this.model.totalSleepMinutes - this.model.bloodOxygen95LastingTime }}</td>

                    </tr>

                    <tr>
                        <td>占比</td>
                        <td>{{ Math.round(this.model.bloodOxygen80Rate) }}%</td>
                        <td>{{ Math.round(this.model.bloodOxygen85Rate - this.model.bloodOxygen80Rate) }}%</td>
                        <td>{{ Math.round(this.model.bloodOxygen90Rate - this.model.bloodOxygen85Rate) }}%</td>
                        <td>{{ Math.round(this.model.bloodOxygen95Rate - this.model.bloodOxygen90Rate ) }}%</td>
                        <td>{{ 100-Math.round(this.model.bloodOxygen80Rate)-Math.round(this.model.bloodOxygen85Rate - this.model.bloodOxygen80Rate)-Math.round(this.model.bloodOxygen90Rate - this.model.bloodOxygen85Rate)-Math.round(this.model.bloodOxygen95Rate - this.model.bloodOxygen90Rate )}}%</td>
                    </tr>

                    <tr>
                        <td>平均SpO2</td>
                        <td colspan="2">{{ Math.round(this.model.averageBloodOxygenSaturation) }}%</td>
                        <td style="text-align: left; padding-left: 10px;color:#BC0024 ;font-weight: bold;">最低SpO2</td>
                        <td colspan="2" style="color:#BC0024 ;font-weight: bold;">{{ Math.round(this.model.minBloodOxygenSaturation) }}%</td>
                      
                    </tr>
                    <tr>
                        <td>氧减次数</td>
                        <td colspan="2">{{ this.model.oxygenRadiationTimes? this.model.oxygenRadiationTimes:0 }}次</td>
                        <td style="text-align: left; padding-left: 10px;color:#BC0024 ;font-weight: bold;">氧气减指数</td>
                        <td colspan="2" style="color:#BC0024 ;font-weight: bold;">{{ this.model.oxygenRadiationIndex ?this.model.oxygenRadiationIndex:0 }}次/小时</td>
                      
                    </tr>
                </tbody>
                
            </div>
        </div>
        <!-- <div class="data-sleep">
            <div class="data-sleep-title">睡眠分期</div>

            <div class="data-sleep-table">
                <tbody>
                    <tr>
                        <td></td>
                        <td>清醒期</td>
                        <td>眼动期</td>
                        <td>浅睡期</td>
                        <td>深睡期</td>
                    </tr>
                    <tr>
                        <td>总时长（分钟）</td>
                        <td>{{ this.model.awakeTime }}</td>
                        <td>{{this.model.remTime}}</td>
                        <td>{{this.model.lightSleepTime}}</td>
                        <td>{{this.model.deepSleepTime}}</td>
                    </tr>
                    <tr>
                        <td>占比</td>
                        <td>{{this.model.awakeRatio}}%</td>
                        <td>{{this.model.remTime}}%</td>
                        <td>{{this.model.lightSleepRatio}}%</td>
                        <td>{{this.model.deepSleepRatio}}</td>
                    </tr>
                </tbody>
                
            </div>
        </div> -->
    </div>
</template>
<script>


import { Table, TableColumn } from "element-ui";
export default {
    props: {
        model: Object,
    },
    components: {

        ElTable: Table,
        ElTableColumn: TableColumn,
    },
    data() {
        return {
           rate1:'<80%',
           rate2:'<85%',
           rate3:'<90%',
           rate4:'<95%',
           rate5:'≥ 95%',
        }

    },


    computed: {
        ahiTitle: function () {
            let title = ""
            if (this.model.reportModeType == DEVICE_MONITOR_MODE.儿童模式) {
                title = "OAHI";
            } else {
                title = "AHI";
            }
            return title;
        },
    }

};
</script>

<style lang="scss" scoped>
.data {
    width: rem(716);
    margin-top: rem(10);
    font-size: rem(16);

    font-family: "songti";
    color: #262626;

    &-summary {
        &-title {
            font-size: rem(16);
            font-weight: bold;
            color: #0080FF;
        }

        &-table {
            margin-top: rem(5);
            font-size: rem(16);
            color: black;

            border-collapse: collapse;

            td {
                border: 1px solid black;
                width: rem(179);
                height: rem(30);

                vertical-align: middle;

            }

            td:nth-child(1) {
                padding-left: rem(10);
            }

            td:nth-child(3) {
                padding-left: rem(10)
            }

            td:nth-child(2) {
                text-align: center;
            }

            td:nth-child(4) {
                text-align: center;
            }
        }
    }
    &-breath {
        margin-top: rem(5);
        &-title {
            font-size: rem(16);
            font-weight: bold;
            color: #0080FF;
        }

        &-table {
            margin-top: rem(6);
            font-size: rem(16);
            color: black;


            border-collapse: collapse;

          
            td {
                border: 1px solid black;
                width: rem(179);
                height: rem(30);

                vertical-align: middle;

            }

            td:nth-child(1) {
                padding-left: rem(10);
            }

            td:nth-child(3) {
                padding-left: rem(10)
            }

            td:nth-child(2) {
                text-align: center;
            }

            td:nth-child(4) {
                text-align: center;
            }
        }
    }
    &-spo2 {
        margin-top: rem(5);
        &-title {
            font-size: rem(16);
            font-weight: bold;
            color: #0080FF;
        }

        &-table {
            margin-top: rem(6);
            font-size: rem(16);
            color: black;


            border-collapse: collapse;

          
            td {
                border: 1px solid black;
                width: rem(179);
                height: rem(30);

                vertical-align: middle;

            }

            td:nth-child(1) {
                padding-left: rem(10);
            }

            td:nth-child(3) {
                text-align: center;
            }

            td:nth-child(2) {
                text-align: center;
            }

            td:nth-child(4) {
                text-align: center;
            }
            td:nth-child(5) {
                text-align: center;
            }
            td:nth-child(6) {
                text-align: center;
            }
        }
    }
    &-sleep {
        margin-top: rem(5);
        &-title {
            font-size: rem(16);
            font-weight: bold;
            color: #0080FF;
        }

        &-table {
            margin-top: rem(6);
            font-size: rem(16);
            color: black;


            border-collapse: collapse;

          
            td {
                border: 1px solid black;
                width: rem(179);
                height: rem(30);

                vertical-align: middle;

            }

            td:nth-child(1) {
                padding-left: rem(10);
            }

            td:nth-child(3) {
                text-align: center;
            }

            td:nth-child(2) {
                text-align: center;
            }

            td:nth-child(4) {
                text-align: center;
            }
            td:nth-child(5) {
                text-align: center;
            }
            td:nth-child(6) {
                text-align: center;
            }
        }
    }
   
}


</style>