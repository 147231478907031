<template>
    <div>
        <p class="line-chart-title">{{ chartConfig.info }}</p>
        <div class="line-chart" :id="title"></div>
    </div>
</template>

<script>
import ChartShell from "@c/report-detail/sleep-chart/chart-shell.vue";
import { Line } from "@antv/g2plot";

export default {
    components: {
        ChartShell,
    },

    props: {
        title: String,
        dataArray: Array,
        timeTickArray: Array,
    },

    data() {
        return {
            chartConfig: {
                info: "",
                yMin: 0,
                yMax: 0,
                yTickCount: 0,
                tooltipTitle: "",
                tooltipUnit: "",
            },
        };
    },

    mounted() {
        this.configureChart();
    },

    methods: {
        configureChart() {
            switch (this.title) {
                case "睡眠血氧趋势":
                    this.chartConfig = {
                        info: "血氧(%)",
                        yMin: 40,
                        yMax: 100,
                        yTickCount: 7,
                        tooltipTitle: "血氧",
                        tooltipUnit: "%",
                    };
                    break;
                case "睡眠脉率趋势":
                    let valueArray = this.dataArray.map((item) => {
                        return item.yValue ? item.yValue : 0;
                    });
                    const yMaxRaw = Math.max(...valueArray);
                    const yMax = Math.ceil(yMaxRaw / 10.0) * 10
                    this.chartConfig = {
                        info: "脉率(次/分)",
                        yMin: 40,
                        yMax: yMax,
                        yTickCount: 7,
                        tooltipTitle: "脉率",
                        tooltipUnit: "bpm",
                    };
                    break;
                default:
                    break;
            }
            this.renderChart();
        },

        renderChart() {
            let lineChart = new Line(this.title, {
                //数据
                data: this.dataArray,
                xField: "xValue",
                yField: "yValue",
                connectNulls: false,
                meta: {
                    xValue: {
                        type: "time",
                        alias: "时间",
                        mask: "HH:mm",
                        tickMethod: () => {
                            return this.timeTickArray;
                        },
                    },
                },
                //x轴
                xAxis: {
                    axisLabel: { fontSize: 21 },
                    line: {
                        style: {
                            stroke: "#333",
                        },
                    },
                    tickLine: {
                        style: {
                            stroke: "#333",
                        },
                    },
                },
                //y轴
                yAxis: {
                    min: this.chartConfig.yMin,
                    max: this.chartConfig.yMax,
                    tickCount: this.chartConfig.yTickCount,
                    label: {
                        formatter: (item) => {
                            return item;
                        },
                    },
                    grid: null,
                    line: {
                        style: {
                            stroke: "#333",
                        },
                    },
                    tickLine: {
                        style: {
                            stroke: "#333",
                        },
                    },
                },
                //折线
                color: "#DC143C",
                lineStyle: {
                    lineWidth: 0.8,
                },
                legend: {
                    layout: "horizontal",
                    position: "right",
                },
                tooltip: {
                    showContent: true,
                    showTitle: true,
                    formatter: (item) => {
                        let value = item.yValue ? item.yValue : 0;
                        return {
                            name: this.chartConfig.tooltipTitle,
                            value: `${value}${this.chartConfig.tooltipUnit}`,
                        };
                    },
                },
            });
            lineChart.yAxis = {};
            lineChart.render();
        },
    },
};
</script>

<style lang="scss" scoped>
.line-chart {
    width: 100%;
    height: rem(90);
    box-sizing: border-box;
    padding: 0 rem(0);
    margin-top: rem(10);
    margin-bottom: rem(10);

    &-title {
        font-family: "songti";
        font-size: 12px;
    }
}
</style>