<template>
  <chart-shell :title="title" :info="chartConfig.info">
    <div>
      <div class="line-chart" :id="title"></div>
    </div>
  </chart-shell>
</template>

<script>
import ChartShell from "@c/report-detail/sleep-chart/chart-shell.vue";
import { DateTool } from "@js/date-tool.js";
import { Line } from "@antv/g2plot";
import { SleepChartTool } from "@c/report-detail/sleep-chart/sleep-chart-tool.js";

export default {
  components: {
    ChartShell,
  },

  props: {
    title: String,
    dataArray: Array,
    timeTickArray: Array,
  },

  data() {
    return {
      chartConfig: {
        info: "",
        yMin: 0,
        yMax: 0,
        yTickCount: 0,
        tooltipTitle: "",
        tooltipUnit: "",
      },
    };
  },

  mounted() {
    this.configureChart();
  },

  methods: {
    configureChart() {
      switch (this.title) {
        case "睡眠血氧趋势":
          this.chartConfig = {
            info: "血氧(%)",
            yMin: 40,
            yMax: 100,
            yTickCount: 7,
            tooltipTitle: "血氧",
            tooltipUnit: "%",
          };
          break;
        case "睡眠脉率趋势":
          let valueArray = this.dataArray.map((item) => {
            return item.yValue ? item.yValue : 0;
          });
          const yMax = Math.max(...valueArray);
          this.chartConfig = {
            info: "脉率(次/分)",
            yMin: 0,
            yMax: yMax > 120 ? yMax : 120,
            yTickCount: 7,
            tooltipTitle: "脉率",
            tooltipUnit: "bpm",
          };
          break;
        case "睡眠呼吸趋势":
          this.chartConfig = {
            info: "呼吸率(次/分)",
            yMin: 0,
            yMax: 40,
            yTickCount: 5,
            tooltipTitle: "呼吸",
            tooltipUnit: "bpm",
          };
          break;
        case "睡眠分期趋势":
          this.chartConfig = {
            info: "",
            yMin: 0,
            yMax: 4,
            yTickCount: 5,
            tooltipTitle: "呼吸",
            tooltipUnit: "bpm",
          };
          break;
        case "睡眠呼吸事件":
        
          let eventValueArray = this.dataArray.map((item) => {
            return item.yValue ? item.yValue : 0;
          });
         
          const eventYMax = Math.max(...eventValueArray);
          this.chartConfig = {
            info: "持续时间(s)",
            yMin: 0,
            yMax: eventYMax > 60 ? eventYMax : 60,
            yTickCount: 5,
            tooltipTitle: "持续时间",
            tooltipUnit: "秒",
          };
          break;
        default:
          break;
      }
      this.renderChart();
    },

    renderChart() {
      let lineChart = new Line(this.title, {
        //数据
        data: this.dataArray,
        xField: "xValue",
        yField: "yValue",
        connectNulls: this.title === "睡眠呼吸趋势",
        meta: {
          xValue: {
            type: "time",
            alias: "时间",
            mask: "HH:mm",
            tickMethod: () => {
              return this.timeTickArray;
            },
          },
        },
        //x轴
        xAxis: {
          line: {
            style: {
              stroke: "#333",
            },
          },
          tickLine: {
            style: {
              stroke: "#333",
            },
          },
        },
        //y轴
        yAxis: {
          min: this.chartConfig.yMin,
          max: this.chartConfig.yMax,
          tickCount: this.chartConfig.yTickCount,
          label: {
            formatter: (item) => {
              switch (this.title) {
                case "睡眠分期趋势":
                  return SleepChartTool.sleepPeriodStatusToStr(item);
                default:
                  return item;
              }
            },
          },
          grid: null,
          line: {
            style: {
              stroke: "#333",
            },
          },
          tickLine: {
            style: {
              stroke: "#333",
            },
          },
        },
        //折线
        color: "#DC143C",
        lineStyle: {
          lineWidth: 0.8,
        },
        legend: {
          layout: "horizontal",
          position: "right",
        },
        tooltip: {
          showContent: this.title != "睡眠呼吸事件",
          showTitle: this.title != "睡眠分期趋势" || "睡眠呼吸",
          formatter: (item) => {
            switch (this.title) {
              case "睡眠分期趋势":
                return {
                  name: DateTool.millisecondTimestampToStr(item.xValue, "h:m"),
                  value: SleepChartTool.sleepPeriodStatusToStr(
                    `${item.yValue}`
                  ),
                };
              default:
                let value = item.yValue ? item.yValue : 0;
                return {
                  name: this.chartConfig.tooltipTitle,
                  value: `${value}${this.chartConfig.tooltipUnit}`,
                };
            }
          },
        },
      });
      lineChart.yAxis = {};
      lineChart.render();
    },
  },
};
</script>

<style lang="scss" scoped>

.line-chart {
  width: 100%;
  height: rem(190);
  box-sizing: border-box;
  padding: 0 rem(25);
  margin-top: rem(10);
  margin-bottom: rem(10);
}
</style>