<template>
  <div class="data-abstract-unit">
    <p :class="{ 'data-abstract-unit-key-h': isHighLight }">{{ title }}：</p>
    <input class="data-abstract-unit-value" :value="ahiValue" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
    reportTemplateType: Number,
    isHighLight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ahiValue: function () {
      const ahiValue = parseFloat(this.value);
      if (isNaN(ahiValue) || ahiValue < 0) {
        return "";
      } else {
        return this.value;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.data-abstract-unit {
  color: black;
  font-size: rem(14);
  display: flex;
  align-items: center;
  height: rem(30);

  &-key {
    &-h {
      font-weight: bold;
    }
  }

  &-value {
    width: rem(80);
    margin-left: rem(10);

  }
}
</style>