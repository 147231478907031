<template>
  <div>
    <!-- 台州恩泽医疗中心集团恩泽医院 -->
    <div class="sleep-time-date" v-if="model.reportTemplateType == 3">
      <span>记录日期：</span>
      <span>{{ model.startRecordDate }}</span>
    </div>
    <div class="sleep-time-statistics">
      <div>
        <data-abstract-unit title="开始记录时间" :value="model.startRecordTime"></data-abstract-unit>
        <data-abstract-unit title="结束记录时间" :value="model.stopRecordTime"></data-abstract-unit>
      </div>
      <div>
        <data-abstract-unit title="开始入睡时间" :value="model.startSleepTime"></data-abstract-unit><data-abstract-unit
          title="结束睡眠时间" :value="model.stopSleepTime"></data-abstract-unit>
      </div>
      <div>
        <data-abstract-unit title="睡眠效率" :value="model.sleepEfficiency"></data-abstract-unit>

        <data-abstract-unit class="sleep-time-statistics-sleep-time" title="总睡眠时长（TST）"
          :value="model.sleepTime"></data-abstract-unit>
      </div>
    </div>
  </div>
</template>

<script>
import DataAbstractUnit from "@c/report-detail/data-abstract/data-abstract-unit.vue";

export default {
  components: {
    DataAbstractUnit,
  },

  props: {
    model: Object,
  },
};
</script>

<style lang="scss" scoped>
.sleep-time-statistics {
  width: 100%;
  box-sizing: border-box;
  padding: rem(10) rem(20);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-sleep-time {
    /deep/.data-abstract-unit-value {
      width: rem(85);
    }
  }
}

.sleep-time-date {
  font-size: rem(14);
  padding-left: rem(20);
  margin-top: rem(10);
}
</style>