<template>
  <div class="sleep-event-unit">
    <div class="sleep-event-unit-left">
      <p>{{ title }}：</p>
      <input class="sleep-event-unit-left-value" :value="value" />
    </div>
    <p class="sleep-event-unit-unit">{{ unit }}</p>
    <input class="sleep-event-unit-tip" :value="tip" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
    unit: String,
    tip: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>

.sleep-event-unit {
  color: black;
  font-size: rem(14);
  width: 100%;
  height: rem(30);
  display: flex;
  align-items: center;

  &-left {
    width: 50%;
    display: flex;
    justify-content: space-between;

    &-value {
      text-align: right;
      width: rem(50);
    }
  }

  &-unit {
    margin-left: rem(20);
  }

  &-tip {
    flex: 1;
    text-align: end;
  }
}
</style>